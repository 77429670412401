import { LobbySettings } from "@/ts/business/game/LobbySettings";
import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { GameMode } from "@/ts/business/game/GameMode";
import { PacketOutFindGame } from "@/ts/business/game/server/outbound/PacketOutFindGame";
import { PacketOutCreateGame } from "@/ts/business/game/server/outbound/PacketOutCreateGame";
import { webGameAPI } from "@/app_util/WebGameAPI";
import { PacketInJoinLobby } from "@/ts/business/game/server/inbound/PacketInJoinLobby";
import { matomo } from "@/app_components/Matomo";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { PacketInError } from "@/ts/business/game/server/inbound/PacketInError";
import { ClientError } from "@/ts/business/game/error/ClientError";
import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";
import { APIUser } from "@/ts/business/api/api_schema";


export async function startRemoteGame(
    router: AppRouterInstance,
    user: APIUser | null,
    settings: LobbySettings,
): Promise<void> {
    const lobbyID = settings.getLobbyID();
    if (lobbyID) {
        router.push(`/game/${lobbyID}`);
        return;
    }
    if (!(settings instanceof KnownLobbySettings))
        throw new Error("Expected known game setup settings");
    if (!settings.isRemote())
        throw new Error("Expected remote game setup settings");

    let requestPacket: PacketOut;
    const ruleSettings = settings.getGameSettings();
    if (settings.getMode() === GameMode.ONLINE) {
        requestPacket = new PacketOutFindGame(ruleSettings);
    } else {
        requestPacket = new PacketOutCreateGame(
            settings.getMode(),
            ruleSettings,
            settings.getBotType(),
        );
    }

    const responsePacket = await webGameAPI.createLobby(requestPacket);
    if (responsePacket instanceof PacketInJoinLobby && responsePacket.lobbyID) {
        if (settings.getMode() === GameMode.ONLINE) {
            matomo.recordSearchForLobby(settings);
        } else {
            matomo.recordCreateLobby(settings);
        }
        router.push(`/game/${responsePacket.lobbyID}`);

    } else if (responsePacket instanceof PacketInError) {
        throw new ClientError(responsePacket.getData(), responsePacket.getMessage());

    } else {
        console.error("Unexpected packet:", responsePacket);
        throw new Error("Server sent an invalid response");
    }
}
