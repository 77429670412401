import styles from "./RoyalBanner.module.scss";
import logo from "@/res/logo_text_only.svg";
import Link from "next/link";
import { cn } from "@/ts/util/cn";
import { ByLine } from "@/app_components/social/banner/ByLine";


interface RoyalBannerProps {
    className?: string;
    noLink?: boolean;
    hideByLine?: boolean;
}


export function RoyalBanner({ className, noLink, hideByLine }: RoyalBannerProps) {
    return (
        <div className={cn(className, styles.banner)}>
            {!noLink && (
                <Link
                    href="/"
                    className={styles.logo}
                    style={{ background: `url(${logo.src}) no-repeat 50%` }}>

                    RoyalUr.net
                </Link>
            )}

            {noLink && (
                <div
                    className={styles.logo}
                    style={{ background: `url(${logo.src}) no-repeat 50%` }}>

                    RoyalUr.net
                </div>
            )}

            {!hideByLine && (
                <ByLine noLink={noLink} />
            )}
        </div>
    );
}
