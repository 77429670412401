import styles from "./HomePlayButtons.module.scss";
import RoyalUrButton from "@/app_components/generic/buttons/RoyalUrButton";
import React, { startTransition, useState } from "react";
import { APIOptionalUser, toCompleteUser } from "@/ts/business/api/api_schema";
import { GameClientControls } from "@/ts/business/GameClientControls";
import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";


interface HomePlayButtonsProps {
    clientControls: GameClientControls;
    user: APIOptionalUser;
    gameSettings: KnownLobbySettings | null;
    newUserMode: boolean;
}


export function HomePlayButtons({
    clientControls, user, gameSettings, newUserMode,
}: HomePlayButtonsProps) {
    const completeUser = toCompleteUser(user);
    const [clickedPlay, setClickedPlay] = useState(false);

    let playButtonLabel = "\xA0";
    if (gameSettings) {
        playButtonLabel = (
            newUserMode
                ? "Play your first game"
                : `Play ${gameSettings.getGameType().getName()} rules`
        );
    }
    return (
        <div className={styles.play_buttons}>
            <RoyalUrButton
                style="cta"
                size="wide"
                onClick={() => {
                    if (gameSettings) {
                        startTransition(() => {
                            setClickedPlay(true);
                            clientControls.startGame(gameSettings);
                        });
                    }
                }}>

                {(() => {
                    if (clickedPlay) {
                        if (clientControls.startingGame)
                            return "...";
                        if (clientControls.startGameError)
                            return "Error: " + clientControls.startGameError.message;
                    }
                    return playButtonLabel;
                })()}
            </RoyalUrButton>

            <div className={styles.play_buttons_bottom_row}>
                <RoyalUrButton
                    className={styles.bottom_row_button}
                    href="/game"
                    style="secondary"
                    size="small">

                    Set up a game
                </RoyalUrButton>

                {!newUserMode && !completeUser && (
                    <RoyalUrButton
                        className={styles.bottom_row_button}
                        href={user ? "/setup" : "/register"}
                        style="secondary"
                        size="small">

                        {user ? "Finish creating your account" : "Create an account"}
                    </RoyalUrButton>
                )}
            </div>
        </div>
    );
}
