"use client";
import styles from "./PopupButton.module.scss";
import React, { MouseEvent, MouseEventHandler, MutableRefObject, ReactNode, useRef } from "react";
import { Popup, PopupAlignment, PopupFunctions } from "@/app_components/generic/popup/Popup";
import { cn } from "@/ts/util/cn";
import { useOptionalIsMobile } from "@/app_components/accessibility/DeviceInfoContext";


interface PopupButtonProps {
    buttonClassName?: string;
    contentClassName?: string;
    children: ReactNode;
    popupRef?: MutableRefObject<PopupFunctions | null>;
    buttonContent: ReactNode;
    minPopupWidthPx?: number;
    darkMode?: boolean;
    alignment?: PopupAlignment;
    includeExtraSpaceOnMobile?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
}


export function PopupButton({
    buttonClassName, contentClassName, children, popupRef,
    buttonContent, minPopupWidthPx, darkMode, alignment,
    includeExtraSpaceOnMobile,
    onOpen, onClose,
}: PopupButtonProps) {
    alignment ??= "center";

    const isMobile = useOptionalIsMobile();

    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const internalPopupRef = useRef<PopupFunctions | null>(null);

    const toggleVisibility: MouseEventHandler = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const popup = internalPopupRef.current;
        const button = buttonRef.current;
        if (!popup || !button)
            return;

        if (popup.isOpen()) {
            popup.close();
        } else {
            popup.open(button, alignment);
        }
    };

    return (
        <>
            <Popup
                setPopupFunctions={(elem: PopupFunctions | null) => {
                    if (popupRef) {
                        popupRef.current = elem;
                    }
                    internalPopupRef.current = elem;
                }}
                minPopupWidthPx={minPopupWidthPx}
                containerClassName={styles.popup_container}
                contentsClassName={cn(
                    styles.popup_content,
                    darkMode && styles.dark_popup_content,
                    contentClassName,
                )}
                onOpen={onOpen}
                onClose={onClose}>

                {children}
            </Popup>

            <button
                className={cn(
                    styles.button,
                    buttonClassName,
                )}
                onClick={toggleVisibility}
                ref={buttonRef}>

                {buttonContent}

                {includeExtraSpaceOnMobile && isMobile && (
                    <button
                        className={styles.button_extra_space}
                        onClick={toggleVisibility} />
                )}
            </button>
        </>
    );
}
