import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { LobbySettings } from "@/ts/business/game/LobbySettings";
import { Path } from "@/ts/util/Path";
import { startRemoteGame } from "@/app/game/startRemoteGame";
import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";
import { APIUser } from "@/ts/business/api/api_schema";
import { Role } from "@/ts/business/role/Role";
import { Permission } from "@/ts/business/role/Permission";


function encodeBool(value: boolean): string {
    return value ? "y" : "n";
}


export async function startGame(
    router: AppRouterInstance,
    user: APIUser | null,
    settings: LobbySettings,
): Promise<void> {
    const lobbyID = settings.getLobbyID();
    if (lobbyID) {
        router.push(`/game/${lobbyID}`);
        return;
    }
    if (settings.isRemote() || !(settings instanceof KnownLobbySettings))
        return await startRemoteGame(router, user, settings);

    const params: Record<string, string> = {
        tab: "play",
        mode: settings.getMode().getID(),
        rules: settings.getGameType().getID(),
        bot: settings.getBotType().getID(),
    };

    if (!settings.getGameType().hasSettings()) {
        const roles = Role.list(user);
        if (!Permission.CUSTOM_RULES.hasPermission(roles))
            throw new Error("Only Memburs can use custom rules");

        const gameSettings = settings.getCustomGameSettings();
        params["dice"] = gameSettings.getDice().getID();
        params["path"] = gameSettings.getPaths().getID();
        params["pieces"] = `${gameSettings.getStartingPieceCount()}`;
        params["safe_rosettes"] = encodeBool(gameSettings.areRosettesSafe());
        params["rosettes_reroll"] = encodeBool(gameSettings.doRosettesGrantExtraRolls());
        params["captures_reroll"] = encodeBool(gameSettings.doCapturesGrantExtraRolls());
    }

    router.push(new Path("/game", params).toString());
}
