import { APIPublicGamePreferences, defaultGamePreferences } from "@/ts/business/api/api_schema";
import { getNameColour } from "@/app_components/user/getNameColour";
import { Permission } from "@/ts/business/role/Permission";
import { Role } from "@/ts/business/role/Role";
import { ReactNode } from "react";


interface DisplayNameProps {
    className?: string;
    name: string | ReactNode;
    preferences: APIPublicGamePreferences | null;
    roles: Role[];
}


export function DisplayName({ className, name, preferences, roles }: DisplayNameProps) {
    preferences ??= defaultGamePreferences;

    let nameColour = preferences.nameColour;
    if (!Permission.NAME_COLOURS.hasPermission(roles)) {
        nameColour = "gray";
    }
    return (
        <span
            className={className}
            style={{ color: getNameColour(nameColour).colorCSS }}>

            {name}
        </span>
    );
}
