"use client";

import { createContext, ReactNode, useContext, useEffect, useState } from "react";


export type Orientation = "landscape" | "portrait";
export const LANDSCAPE: Orientation = "landscape";
export const PORTRAIT: Orientation = "portrait";


export type DeviceInfo = {
    width: number;
    height: number;
    remToPx: number;
    orientation: Orientation;
    isMobile: boolean;
    isDesktop: boolean;
    isDefault: boolean;
};


export function readCurrentDeviceInfo(): DeviceInfo {
    if (typeof window === "undefined" || typeof document === "undefined")
        return DEFAULT_DEVICE_INFO;

    const width = window.innerWidth;
    const height = window.innerHeight;
    const orientation = (width > height ? LANDSCAPE : PORTRAIT);
    const remToPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const isMobile = (width <= remToPx * 48);
    const isDesktop = (width >= remToPx * 80);
    const isDefault = false;
    return { width, height, remToPx, orientation, isMobile, isDesktop, isDefault };
}


const DEFAULT_DEVICE_INFO: DeviceInfo = {
    width: 1920,
    height: 1080,
    remToPx: 16,
    orientation: LANDSCAPE,
    isMobile: false,
    isDesktop: false,
    isDefault: true,
};


const DeviceInfoContext = createContext<DeviceInfo | null>(null);


export function useOptionalDeviceInfo(): DeviceInfo | null {
    const deviceInfo = useContext(DeviceInfoContext);
    return deviceInfo ?? null;
}


export function useDeviceInfo(): DeviceInfo {
    const deviceInfo = useContext(DeviceInfoContext);
    if (!deviceInfo)
        throw new Error("useDeviceInfo must be used within a DeviceInfoContext");

    return deviceInfo;
}


export function useOptionalIsMobile(): boolean | null {
    const deviceInfo = useOptionalDeviceInfo();
    return deviceInfo && deviceInfo.isMobile;
}


export function useIsMobile(): boolean {
    const deviceInfo = useDeviceInfo();
    return deviceInfo.isMobile;
}


export function useOptionalIsDesktop(): boolean | null {
    const deviceInfo = useOptionalDeviceInfo();
    return deviceInfo && deviceInfo.isDesktop;
}


export function useIsDesktop(): boolean {
    const deviceInfo = useDeviceInfo();
    return deviceInfo.isDesktop;
}


export function useOptionalOrientation(): Orientation | null {
    const deviceInfo = useOptionalDeviceInfo();
    return deviceInfo?.orientation ?? null;
}


export function useOrientation(): Orientation {
    const deviceInfo = useDeviceInfo();
    return deviceInfo.orientation;
}


interface DeviceInfoProviderProps {
    children: ReactNode;
}


export function DeviceInfoProvider({ children }: DeviceInfoProviderProps) {
    const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(DEFAULT_DEVICE_INFO);

    useEffect(() => {
        const updateDeviceInfo = () => {
            setDeviceInfo(readCurrentDeviceInfo());
        };
        updateDeviceInfo();

        let timeoutID: number | NodeJS.Timeout | null = null;
        const updateOrientationWithTimeout = () => {
            updateDeviceInfo();

            if (timeoutID !== null) {
                clearTimeout(timeoutID);
            }

            timeoutID = setTimeout(() => {
                timeoutID = null;
                updateDeviceInfo();
            }, 100);
        };

        if (screen.orientation) {
            screen.orientation.addEventListener("change", updateOrientationWithTimeout);
        }
        window.addEventListener("resize", updateOrientationWithTimeout);

        return () => {
            if (timeoutID !== null) {
                clearTimeout(timeoutID);
            }
            if (screen.orientation) {
                screen.orientation.removeEventListener("change", updateOrientationWithTimeout);
            }
            window.removeEventListener("resize", updateOrientationWithTimeout);
        };
    }, []);

    return (
        <DeviceInfoContext.Provider value={deviceInfo}>
            {children}
        </DeviceInfoContext.Provider>
    );
}
