import styles from "./PopupBase.module.scss";
import { useDocument } from "@/app_util/ssrHelper";
import { MutableRefObject, ReactNode, useEffect, useRef } from "react";
import { cn } from "@/ts/util/cn";
import { usePathname } from "next/navigation";
import { BodyPortal } from "@/app_components/generic/BodyPortal";
import { motion, AnimatePresence, MotionProps } from "framer-motion";
import { fadeInOut } from "@/app_util/fadeInOut";


interface PopupBaseProps {
    children?: ReactNode;
    visible: boolean;
    close: (reset?: boolean) => void;

    contentsRef: MutableRefObject<HTMLDivElement | null>;
    motionParams?: MotionProps;
}


export function PopupBase({
    children, visible, close,
    contentsRef, motionParams,
}: PopupBaseProps) {
    if (!motionParams) {
        motionParams = fadeInOut();
    }

    const doc = useDocument();

    useEffect(() => {
        if (!visible || !doc)
            return;

        // Close the popup if the user clicks outside of it.
        const listener = (event: MouseEvent | TouchEvent) => {
            const contentsElem = contentsRef.current;
            if (contentsElem) {
                const clientXYs: [number, number][] = [];
                if (event instanceof MouseEvent) {
                    clientXYs.push([event.clientX, event.clientY]);

                } else if (event instanceof TouchEvent) {
                    for (let index = 0; index < event.touches.length; ++index) {
                        const touch = event.touches[index];
                        clientXYs.push([touch.clientX, touch.clientY]);
                    }
                }

                const bounds = contentsElem.getBoundingClientRect();
                for (const loc of clientXYs) {
                    const [clientX, clientY] = loc;
                    const withinX = (clientX >= bounds.left && clientX <= bounds.right);
                    const withinY = (clientY >= bounds.top && clientY <= bounds.bottom);
                    if (withinX && withinY)
                        return;
                }
            }
            close();
        };
        const onResize = () => {
            close(true);
        };

        const options = { capture: true, passive: true };
        doc.addEventListener("click", listener, options);
        doc.addEventListener("touchstart", listener, options);
        if (screen.orientation) {
            screen.orientation.addEventListener("change", onResize);
        }
        document.addEventListener("resize", onResize);
        return () => {
            doc.removeEventListener("click", listener, options);
            doc.removeEventListener("touchstart", listener, options);
            if (screen.orientation) {
                screen.orientation.removeEventListener("change", onResize);
            }
            document.removeEventListener("resize", onResize);
        };
    }, [visible, doc, contentsRef, close]);

    const pathName = usePathname();
    const pathNameRef = useRef(pathName);
    useEffect(() => {
        if (pathName !== pathNameRef.current) {
            pathNameRef.current = pathName;
            close();
        }
    }, [pathName, close]);

    return (
        <BodyPortal>
            <AnimatePresence>
                {visible && (
                    <motion.div
                        key="popup_content"
                        className={cn(
                            styles.container,
                            styles.visible,
                        )}
                        {...motionParams}>

                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </BodyPortal>
    );
}
