import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { GameSettings } from "@/ts/royalur/model/GameSettings";
import { GameMode } from "@/ts/business/game/GameMode";
import { BotType } from "@/ts/business/game/BotType";


export class PacketOutCreateGame extends PacketOut {
    private readonly gameMode: GameMode;
    private readonly settings: GameSettings;
    private readonly botType: BotType | null;

    constructor(
        gameMode: GameMode,
        settings: GameSettings,
        botType: BotType | null,
    ) {
        super(OutboundPacketType.CREATE_GAME);
        if (gameMode === GameMode.COMPUTER && botType === null)
            throw new Error("Computer games must provide a bot type");

        this.gameMode = gameMode;
        this.settings = settings;
        this.botType = botType;
    }

    override writeContents(context: MessageIOContext): Record<string, any> {
        return {
            game_mode: this.gameMode.getID(),
            settings: context.getGameNotation().writeGameSettings(this.settings),
            ...(this.botType !== null ? {
                bot_type: this.botType.getID(),
            } : {}),
        };
    }
}
