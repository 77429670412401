

export const AuthRedirectTarget = {
    SIGN_IN: "SIGN_IN",
    REGISTER: "REGISTER",
    HOME: "HOME",
    ACCOUNT_SETUP: "ACCOUNT_SETUP",
    DELETION_STATUS: "DELETION_STATUS",
} as const;

export type AuthRedirectTarget = typeof AuthRedirectTarget[keyof typeof AuthRedirectTarget];
