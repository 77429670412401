import { useRef } from "react";


/**
 * Used to create a value that will never be re-created
 * from when a component is first rendered.
 */
export function useSingleton<T>(constructor: () => T): T {
    const ref = useRef<T | null>(null);
    if (ref.current === null) {
        ref.current = constructor();
    }
    return ref.current;
}
