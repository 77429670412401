import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { MoveGameEvent } from "@/ts/business/game/event/MoveGameEvent";
import { RollGameEvent } from "@/ts/business/game/event/RollGameEvent";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Agent } from "@/ts/royalur/agent/Agent";
import { Game } from "@/ts/royalur/Game";
import { getTimeSeconds, getTimeMs } from "@/ts/util/utils";
import { GameMode } from "@/ts/business/game/GameMode";
import { GameUpdateEvent } from "@/ts/business/game/controller/source/GameUpdateEvent";
import { GamePlayers } from "@/ts/business/api/game/GamePlayers";
import { GamePlayerData } from "@/ts/business/api/game/GamePlayerData";
import { BotType } from "@/ts/business/game/BotType";
import { AnalyticsProvider } from "@/ts/business/analytics/Analytics";
import { LobbySettings } from "@/ts/business/game/LobbySettings";
import { MessageOutUploadGame } from "@/ts/business/api/game/MessageOutUploadGame";
import { BotLobbyPlayer } from "@/ts/business/lobby/BotLobbyPlayer";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { GameAPI } from "@/ts/business/api/GameAPI";
import { APIGamePreferences } from "@/ts/business/api/api_schema";
import { GameClientControls } from "@/ts/business/GameClientControls";
import { ReactionType } from "@/ts/business/game/ReactionType";
import { LocalLobbyStatus } from "@/ts/business/game/controller/status/LocalLobbyStatus";
import { GameController } from "@/ts/business/game/controller/GameController";
import { LobbyID } from "@/ts/business/lobby/LobbyID";


export class SelfPlayComputerGameSource extends GameSource<LocalLobbyStatus> {
    public static readonly CHECK_INTERVAL_MS = 350;

    private readonly botType: BotType;
    private readonly botAgent: Agent;

    private currentAgentPromise: Promise<Game> | null = null;
    private gameController: GameController | null = null;
    private lastMonitorMs: number;

    constructor(
        initialClientControls: GameClientControls,
        initialPreferences: APIGamePreferences,
        analytics: AnalyticsProvider,
        gameAPI: GameAPI,
        setupSettings: LobbySettings,
        rules: RuleSet,
        botType: BotType,
        botAgent: Agent,
    ) {
        super(
            initialClientControls, initialPreferences, analytics, gameAPI, setupSettings,
            new LocalLobbyStatus(
                LobbyID.generateRandom(),
                GameMode.COMPUTER, botType, null, null, null,
            ),
            rules,
        );
        if (botType.isOnline())
            throw new Error("Bot type of " + botType.getName() + " requires an online game source");

        this.botType = botType;
        this.botAgent = botAgent;

        this.lastMonitorMs = getTimeMs();
        this.handleGameSetup();
    }

    override getControllerMaxDirectives(): number {
        // A value of 0 represents no limit.
        return 0;
    }

    override isLocalHumanPlayer(_playerType: PlayerType): boolean {
        return false;
    }

    private handleGameSetup(): void {
        const game = this.getRules().generateGame();

        const lightData = new GamePlayerData(1, PlayerType.LIGHT);
        const darkData = new GamePlayerData(2, PlayerType.DARK);

        const lightPlayer = new BotLobbyPlayer(this.botType, lightData);
        const darkPlayer = new BotLobbyPlayer(this.botType, darkData);

        this.updateGameAndPlayers(
            new GamePlayers(
                PlayerType.LIGHT,
                null,
                lightPlayer,
                darkPlayer,
            ),
            game, new GameUpdateEvent(true),
        );
        this.lobbyStatus.map(status => status.withCompleteGameID(null));
    }

    override setup(): () => void {
        const interval = setInterval(() => {
            this.monitor();
        }, 250);

        return () => {
            clearInterval(interval);
        };
    }

    linkGameController(gameController: GameController): () => void {
        this.gameController = gameController;
        return () => {
            this.gameController = null;
        };
    }

    monitor() {
        const timeSinceMonitorMs = getTimeMs() - this.lastMonitorMs;
        if (timeSinceMonitorMs < SelfPlayComputerGameSource.CHECK_INTERVAL_MS)
            return;

        this.maybePlayAgentTurn();
        this.lastMonitorMs = getTimeMs();
    }

    override handleResign(): void {
        throw new Error("Unimplemented for self-play games");
    }

    override handleMove(_event: MoveGameEvent): void {
        throw new Error("Unimplemented for self-play games");
    }

    override handleRoll(_event: RollGameEvent): void {
        throw new Error("Unimplemented for self-play games");
    }

    override handleReaction(_player: PlayerType | null, _reaction: ReactionType) {
        throw new Error("Unimplemented for self-play games");
    }

    private maybePlayAgentTurn() {
        // Agent turn is in progress.
        if (this.currentAgentPromise !== null)
            return;
        if (this.gameController === null)
            return;
        if (this.gameController.getQueuedDirectiveCount() > 2)
            return;

        const game = this.game.get();
        if (game.isFinished()) {
            // Wait until this game finishes being animated.
            if (this.gameController.getQueuedDirectiveCount() > 0)
                return;

            this.handleGameSetup();
            return;
        }

        const startTime = getTimeSeconds();
        this.currentAgentPromise = this.botAgent.playTurn(game);

        this.currentAgentPromise.then((newGame: Game) => {
            this.currentAgentPromise = null;

            const duration = getTimeSeconds() - startTime;
            if (duration > 0.1) {
                console.log(`AI took ${duration.toFixed(3)} seconds`);
            }
            this.game.set(newGame, new GameUpdateEvent(false), game);
        });
    }

    override shouldUploadGame(): boolean {
        return false;
    }

    override buildUploadGameMessage(): MessageOutUploadGame {
        throw new Error("Unimplemented for self-play games");
    }
}
