"use client";

import styles from "./Form.module.scss";
import { useRouter } from "next/navigation";
import { FormEvent, ReactNode, useRef, useState } from "react";
import { submitFormData } from "@/app_components/form/FormResponse";
import { FormState, FormStateProvider, useFormState } from "@/app_components/form/FormStateContext";
import { useMutation } from "@tanstack/react-query";
import CheckIcon from "@/app_components/icon/CheckIcon";


export function FormStatus() {
    const formState = useFormState();
    return (
        <div className={styles.status}>
            {formState.submitError && (
                <p className={styles.error}>
                    {formState.submitError}
                </p>
            )}

            {formState.successMessage && (
                <>
                    <CheckIcon className={styles.check} />
                    <p className={styles.success}>
                        {formState.successMessage}
                    </p>
                </>
            )}
        </div>
    );
}


export interface FormProps {
    children?: ReactNode;
    className?: string;
    action: string;
    validationError: string | null;
    autoComplete?: string;
    skipRefreshOnSubmit?: boolean;
    acceptResponseJSON?: (json: any) => void;
}


export function Form({
    children, className, action, validationError, autoComplete,
    skipRefreshOnSubmit, acceptResponseJSON,
}: FormProps) {
    const router = useRouter();
    const [successMessage, setSuccessMessage] = useState("");
    const [redirecting, setRedirecting] = useState(false);

    const formRef = useRef<HTMLFormElement | null>(null);

    const formMutation = useMutation({
        mutationFn: async (formData: FormData) => {
            if (validationError)
                return;

            setSuccessMessage("");
            const response = await submitFormData(action, formData);
            if (response.redirect) {
                setSuccessMessage("You are being redirected...");
                setRedirecting(true);
                window.location.href = response.redirect;
                return;
            }

            if (!skipRefreshOnSubmit) {
                router.refresh();
            }

            if (response.message) {
                setSuccessMessage(response.message);
                setRedirecting(false);
                if (acceptResponseJSON) {
                    acceptResponseJSON(response.json);
                }
                return;
            }

            let error = response.error;
            if (!error) {
                error = "An unexpected error occurred";
            }
            throw new Error(error);
        },
    });

    // Used when submitting a form on unmount.
    const formDataCache = useRef<FormData>();
    if (formRef.current) {
        formDataCache.current = new FormData(formRef.current);
    }

    const formState: FormState = {
        isPending: formMutation.isPending || redirecting,
        validationError: validationError ?? undefined,
        submit: () => {
            let formData;
            if (formRef.current) {
                formData = new FormData(formRef.current);
            } else {
                formData = formDataCache.current;
            }
            if (formData) {
                formMutation.mutate(formData);
            }
        },
        updateFormDataCache: () => {
            if (formRef.current) {
                formDataCache.current = new FormData(formRef.current);
            }
        },
        submitError: formMutation.error?.message,
        successMessage: successMessage,
    };

    return (
        <div className={`${className} ${styles.form}`}>
            <FormStateProvider formState={formState}>
                <form
                    action={action}
                    method="post"
                    onSubmit={(event: FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        formMutation.mutate(formData);
                    }}
                    autoComplete={autoComplete}
                    ref={formRef}>

                    {children}
                </form>
            </FormStateProvider>
        </div>
    );
}
