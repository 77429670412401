import styles from "./SmallSocialLinks.module.scss";
import Link from "next/link";
import {
    FACEBOOK_PAGE_URL,
} from "@/ts/business/constants";
import Image from "next/image";
import facebookIcon from "@/res/icons/facebook.svg";
import { cn } from "@/ts/util/cn";


interface SmallSocialLinksProps {
    className?: string;
}


export function SmallSocialLinks({ className }: SmallSocialLinksProps) {
    return (
        <div className={cn(
            styles.social_container,
            className,
        )}>
            <Link href={FACEBOOK_PAGE_URL}>
                <div className={styles.social_icon}>
                    <Image
                        src={facebookIcon}
                        alt="Facebook"
                        width="48"
                        height="48" />
                </div>

                <p>
                    Like our page on Facebook
                </p>
            </Link>
        </div>
    );
}
