import { APINameColour } from "@/ts/business/api/api_schema";


export interface NameColour {
    id: string;
    name: string;
    colorCSS: string;
    lightColorCSS: string;
}


export const nameColours: NameColour[] = [
    {
        id: "gray",
        name: "Gray",
        colorCSS: "hsl(230, 10%, 77%)",
        lightColorCSS: "hsl(230, 13%, 91%)",
    },
    {
        id: "red",
        name: "Red",
        colorCSS: "hsl(8, 100%, 67%)",
        lightColorCSS: "hsl(12, 100%, 72%)",
    },
    {
        id: "green",
        name: "Green",
        colorCSS: "hsl(122, 65%, 57%)",
        lightColorCSS: "hsl(126, 72%, 62%)",
    },
    {
        id: "blue",
        name: "Blue",
        colorCSS: "hsl(207, 100%, 67%)",
        lightColorCSS: "hsl(203, 100%, 72%)",
    },
    {
        id: "gold",
        name: "Gold",
        colorCSS: "hsl(49, 90%, 51%)",
        lightColorCSS: "hsl(49, 100%, 60%)",
    },
    {
        id: "orange",
        name: "Orange",
        colorCSS: "hsl(32, 100%, 61%)",
        lightColorCSS: "hsl(30, 100%, 65%)",
    },
    {
        id: "pink",
        name: "Pink",
        colorCSS: "hsl(302, 65%, 71%)",
        lightColorCSS: "hsl(302, 65%, 77%)",
    },
];


export function getNameColour(colour: APINameColour): NameColour {
    for (const nameColour of nameColours) {
        if (nameColour.id === colour)
            return nameColour;
    }
    throw new Error(`Missing name colour ${colour}`);
}
