import { Role } from "@/ts/business/role/Role";


export class Permission {
    public static readonly NAME_COLOURS = new Permission(
        "name_colours", "Name Colours",
        "Whether a player can choose their own name colour",
        [Role.MEMBUR, Role.TEAM, Role.ADMIN],
    );

    public static readonly MEMBUR_REACTIONS = new Permission(
        "membur_reactions", "Membur Reactions",
        "Whether a player can use the exclusive membur reactions",
        [Role.MEMBUR, Role.TEAM, Role.ADMIN],
    );

    public static readonly UNLIMITED_PANDA_GAMES = new Permission(
        "unlimited_panda_games", "Unlimited Panda Games",
        "Whether a player can play unlimited games against the Panda",
        [Role.MEMBUR, Role.TEAM, Role.ADMIN],
    );

    public static readonly CUSTOM_RULES = new Permission(
        "custom_rules", "Custom Rules",
        "Whether a player can make games with custom rules",
        [Role.STANDARD, Role.MEMBUR, Role.TEAM, Role.ADMIN],
    );

    public static readonly GAME_ANALYSIS = new Permission(
        "game_analysis", "Game Analysis",
        "Whether a player can request analysis of their games",
        [Role.MEMBUR, Role.TEAM, Role.ADMIN],
    );

    public static readonly NO_ADS = new Permission(
        "no_ads", "No Ads",
        "Whether a player is not shown ads",
        [Role.MEMBUR, Role.TEAM],
    );

    public static readonly ADMIN_USER_MANAGEMENT = new Permission(
        "admin_user_management", "Admin User Management",
        "Whether a player can perform admin tasks for user management",
        [Role.ADMIN],
    );

    public static values(): Permission[] {
        return [
            Permission.NAME_COLOURS,
            Permission.MEMBUR_REACTIONS,
            Permission.UNLIMITED_PANDA_GAMES,
            Permission.CUSTOM_RULES,
            Permission.GAME_ANALYSIS,
            Permission.NO_ADS,
            Permission.ADMIN_USER_MANAGEMENT,
        ];
    }

    private readonly id: string;
    private readonly name: string;
    private readonly desc: string;
    private readonly roles: Role[];

    constructor(id: string, name: string, desc: string, roles: Role[]) {
        this.id = id;
        this.name = name;
        this.desc = desc;
        this.roles = roles;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getDesc(): string {
        return this.desc;
    }

    getRoles(): Role[] {
        return this.roles;
    }

    hasPermission(userRoles: Role[]): boolean {
        for (const role of userRoles) {
            if (this.roles.includes(role))
                return true;
        }
        return false;
    }

    static getByID(id: string): Permission {
        for (const diff of Permission.values()) {
            if (diff.getID() === id)
                return diff;
        }
        throw new Error(`Unknown computer difficulty ID: ${id}`);
    }

    static getByIDOrNull(id: string): Permission | null {
        for (const diff of Permission.values()) {
            if (diff.getID() === id)
                return diff;
        }
        return null;
    }
}
