import { z } from "zod";
import { Path } from "@/ts/util/Path";
import { AuthCheck } from "@/ts/auth/check/AuthCheck";
import { getAuthRedirectPath } from "@/app_util/auth/getAuthRedirectPath";
import { fetchWithThrow } from "@/app_util/fetch_util";


export const FormResponse = z.object({
    message: z.ostring(),
    error: z.ostring(),
    redirect: z.ostring(),
    json: z.unknown(),
});
export type FormResponse = z.infer<typeof FormResponse>;


export function makeFormResponse(status: number, response: FormResponse) {
    return new Response(
        JSON.stringify(response),
        { status: status },
    );
}


export function makeFormErrorResponse(error: string) {
    return new Response(error, { status: 400 });
}


export function makeFormSuccessResponse(data: FormResponse) {
    return makeFormResponse(200, data);
}


export function makeFormRedirectResponse(path: Path) {
    return makeFormSuccessResponse({
        redirect: path.toString(),
    });
}


export function makeFormAuthRedirectResponse(authCheck: AuthCheck) {
    return makeFormRedirectResponse(getAuthRedirectPath(authCheck.getRedirectTarget()));
}


export async function submitFormData(action: string, formData: FormData): Promise<FormResponse> {
    const response = await fetchWithThrow(action, {
        method: "POST",
        body: formData,
        redirect: "manual",
        credentials: "include",
    });

    let data;
    try {
        const json = await response.json();
        data = FormResponse.parse(json);
    } catch (err) {
        console.error(err);
        return { error: "Form got an invalid response" };
    }
    if (response.status === 0 || response.ok)
        return data;

    let error = data.error;
    if (!error) {
        if (response.status !== 0) {
            error = `${response.status}: ${response.statusText}`;
        } else {
            error = "An unexpected error occurred";
        }
    }
    return { error: error };
}
