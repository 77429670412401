import styles from "./Popup.module.scss";
import { ReactNode, useRef, useState, useCallback, CSSProperties } from "react";
import { PopupBase } from "@/app_components/generic/popup/PopupBase";
import { fadeInOut } from "@/app_util/fadeInOut";
import { getTimeMs } from "@/ts/util/utils";
import { cn } from "@/ts/util/cn";
import { MotionProps } from "framer-motion";


export type PopupAlignment = "center" | "left" | "right";


export interface PopupFunctions {
    isOpen: () => boolean;
    open: (anchorButton?: HTMLElement, alignment?: PopupAlignment) => void;
    close: (resetImmediately?: boolean) => void;
}


interface PopupProps {
    children?: ReactNode;
    setPopupFunctions: (popup: PopupFunctions) => void;
    minPopupWidthPx?: number;

    containerClassName?: string;
    contentsClassName?: string;
    motionParams?: MotionProps;

    onOpen?: () => void;
    onClose?: () => void;
}


export function Popup({
    children,
    setPopupFunctions,
    minPopupWidthPx,
    containerClassName,
    contentsClassName,
    motionParams,
    onOpen,
    onClose,
}: PopupProps) {
    if (!motionParams) {
        motionParams = fadeInOut();
    }

    const [containerStyles, setContainerStyles] = useState<CSSProperties | undefined>(undefined);
    const contentsRef = useRef<HTMLDivElement | null>(null);
    const [visible, setVisible] = useState(false);
    const lastCloseTimeRef = useRef<number>(getTimeMs());
    const closeResetTimerRef = useRef<any | null>(null);

    const close = useCallback((resetImmediately?: boolean) => {
        if (visible) {
            setVisible(false);
            lastCloseTimeRef.current = getTimeMs();
            if (onClose) {
                onClose();
            }
        }

        const reset = () => setContainerStyles({
            opacity: 0, left: 0, top: 0,
        });

        if (resetImmediately) {
            reset();
        } else {
            if (closeResetTimerRef.current) {
                clearTimeout(closeResetTimerRef.current);
                closeResetTimerRef.current = null;
            }
            closeResetTimerRef.current = setTimeout(reset, 500);
        }
    }, [visible, setVisible, lastCloseTimeRef, onClose, setContainerStyles]);

    const open = useCallback((anchorButton?: HTMLElement, alignment?: PopupAlignment) => {
        if (visible || getTimeMs() - lastCloseTimeRef.current < 500)
            return;

        if (closeResetTimerRef.current) {
            clearTimeout(closeResetTimerRef.current);
            closeResetTimerRef.current = null;
        }

        if (anchorButton) {
            const bounds = anchorButton.getBoundingClientRect();
            const minPopupWidth = (minPopupWidthPx ?? 200) + 20;

            let targetLeft;
            if (alignment === "center") {
                targetLeft = (bounds.left + bounds.right - minPopupWidth) / 2;
            } else if (alignment === "left") {
                targetLeft = bounds.right - minPopupWidth + 10;
            } else if (alignment === "right") {
                targetLeft = bounds.left - 10;
            } else {
                throw new Error(`Unknown alignment ${alignment}`);
            }

            const maxLeft = window.innerWidth - minPopupWidth;
            setContainerStyles({
                opacity: "1",
                left: Math.max(0, Math.min(targetLeft, maxLeft)) + "px",
                top: (bounds.bottom + window.scrollY) + "px",
            });
        }

        setVisible(true);
        if (onOpen) {
            onOpen();
        }
    }, [visible, minPopupWidthPx, setVisible, onOpen, setContainerStyles]);

    setPopupFunctions({
        isOpen: () => visible,
        open, close,
    });

    return (
        <PopupBase
            visible={visible}
            close={close}
            contentsRef={contentsRef}
            motionParams={motionParams}>

            <div
                className={cn(
                    styles.container,
                    containerClassName,
                )}
                style={containerStyles}>

                <div
                    className={contentsClassName}
                    ref={contentsRef}>

                    {children}
                </div>
            </div>
        </PopupBase>
    );
}
