import victorBoard from "@/res/photos/victor_board.jpg";
import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";


export const rulesArticle: ArticleMetadata = {
    id: "rules",
    shortTitle: "Rules of the Royal Game of Ur",
    fullTitle: "Rules of the Royal Game of Ur",
    path: "/rules",
    dir: [
        { label: "Home", href: "/" },
        { label: "Learn", href: "/learn" },
    ],
    image: {
        src: victorBoard,
        alt: "A photo of a Royal Game of Ur board that was made using resin.",
    },
    description: (
        "The Royal Game of Ur is war! You roll the dice and battle to move "
        + "your pieces around and off the board before your opponent."
    ),
    htmlDescription: <>
        The Royal Game of Ur is war! You roll the dice and battle to move
        your pieces around and off the board before your opponent.
    </>,
};
