import { createContext, ReactNode, useContext } from "react";


export interface FormState {
    isPending: boolean;
    validationError?: string;
    submitError?: string;
    successMessage?: string;
    submit: () => void;
    updateFormDataCache: () => void;
}


const FormStateContext = createContext<FormState | null>(null);

export function useFormState(): FormState {
    const formState = useContext(FormStateContext);
    if (formState === null)
        throw new Error("Missing form state");

    return formState;
}


interface FormStateProviderProps {
    children: ReactNode;
    formState: FormState;
}


export function FormStateProvider({ children, formState }: FormStateProviderProps) {
    return (
        <FormStateContext.Provider value={formState}>
            {children}
        </FormStateContext.Provider>
    );
}
