import styles from "./RoyalCard.module.scss";
import { ReactNode } from "react";
import { Card } from "@/app_components/generic/card/Card";
import { cn } from "@/ts/util/cn";


interface RoyalCardProps {
    children: ReactNode;
    className?: string;
    style?: "default" | "dark";
}


export function RoyalCard({ children, className, style }: RoyalCardProps) {
    return (
        <Card className={cn(
            className,
            styles.card,
            style === "dark" && styles.dark,
        )}>
            {children}
        </Card>
    );
}
