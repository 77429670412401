"use client";

import styles from "./RandomGame.module.scss";
import { useSingleton } from "@/app_util/useSingleton";
import { GameClientControls } from "@/ts/business/GameClientControls";
import { useGamePreferences } from "@/app/(api)/api/getGamePreferences/useGamePreferences";
import { useOptionalUser, UserProvider } from "@/app_components/user/UserContext";
import { matomo } from "@/app_components/Matomo";
import { webGameAPI } from "@/app_util/WebGameAPI";
import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";
import { SelfPlayComputerGameSource } from "@/ts/business/game/controller/source/SelfPlayComputerGameSource";
import { SimpleRuleSetProvider } from "@/ts/royalur/rules/simple/SimpleRuleSetProvider";
import { BotType } from "@/ts/business/game/BotType";
import { DeviceInfoProvider } from "@/app_components/accessibility/DeviceInfoContext";
import { AssetProvider } from "@/app_components/assets/AssetContext";
import { GameUIContext, useGameController } from "@/app_components/game/GameUIContext";
import React, { useEffect } from "react";
import BoardUI from "@/app_components/game/board/BoardUI";
import { useCreateSharedRendererState } from "@/app_components/game/SharedRendererState";
import { GameMode } from "@/ts/business/game/GameMode";
import { GameType } from "@/ts/business/game/GameType";
import { GameController } from "@/ts/business/game/controller/GameController";
import { cn } from "@/ts/util/cn";


interface GameControllerCaptureProps {
    capture: (gameController: GameController) => (() => void);
}


function GameControllerCapture({ capture }: GameControllerCaptureProps) {
    const gameController = useGameController();
    useEffect(() => {
        return capture(gameController);
    }, [capture, gameController]);
    return (<></>);
}


interface RandomGameProps {
    className?: string;
    orientation?: "horizontal" | "vertical";
    inArticle?: boolean;
}


export function RandomGame({ className, orientation, inArticle }: RandomGameProps) {
    const clientControls = useSingleton<GameClientControls>(() => {
        const missing = () => {
            throw new Error("This function is missing!");
        };
        return {
            startGame: missing,
            startingGame: false,
            startGameError: null,
            clearError: missing,
            resignGame: missing,
            abortGame: missing,
            notify: missing,
        };
    });

    const user = useOptionalUser();
    const { preferences } = useGamePreferences(user);
    const gameSource = useSingleton(() => {
        const settings = new KnownLobbySettings(
            GameMode.COMPUTER,
            GameType.FINKEL,
            GameType.FINKEL.getSettings(),
            BotType.EASY,
        );
        const rules = new SimpleRuleSetProvider().create(settings.getGameSettings());
        return new SelfPlayComputerGameSource(
            clientControls,
            preferences,
            matomo,
            webGameAPI,
            settings,
            rules,
            settings.getBotType(),
            settings.getBotType().createAgent(rules),
        );
    });

    const sharedRendererState = useCreateSharedRendererState();

    return (
        <UserProvider user={user}>
            <DeviceInfoProvider>
                <AssetProvider>
                    <GameUIContext gameSource={gameSource} clientControls={clientControls}>
                        <GameControllerCapture
                            capture={gameController => gameSource.linkGameController(gameController)} />

                        <div className={cn(
                            styles.container,
                            inArticle && styles.in_article,
                            className,
                        )}>
                            <BoardUI
                                vertical={orientation === "vertical"}
                                horizontal={!orientation || orientation === "horizontal"}
                                isMobile={true}
                                isPathActive={false}
                                sharedRendererState={sharedRendererState} />
                        </div>
                    </GameUIContext>
                </AssetProvider>
            </DeviceInfoProvider>
        </UserProvider>
    );
}
