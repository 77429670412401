

/**
 * The end-part of a URL.
 */
export class Path {
    private readonly path: string;
    private readonly params?: { [key: string]: string };
    private readonly anchor?: string;

    constructor(path: string, params?: { [key: string]: string }, anchor?: string) {
        this.path = path;
        this.params = params;
        this.anchor = anchor;
    }

    withParam(key: string, value: string): Path {
        return new Path(
            this.path,
            {
                ...(this.params ?? {}),
                [key]: value,
            },
            this.anchor,
        );
    }

    withOptionalParam(key: string, value?: string): Path {
        return (value !== undefined ? this.withParam(key, value) : this);
    }

    getBasePath(): string {
        return this.path;
    }

    toString() {
        let path;
        if (this.anchor) {
            path = `${this.path}#${this.anchor}`;
        } else {
            path = this.path;
        }

        if (!this.params)
            return path;

        const searchParams = new URLSearchParams();
        let length = 0;
        for (const key in this.params) {
            searchParams.set(key, this.params[key]);
            length += 1;
        }
        return (length === 0 ? path : `${path}?${searchParams.toString()}`);
    }
}
