import { GameType } from "@/ts/business/game/GameType";
import finkelBoardIcon from "@/res/buttons/board_finkel.svg";
import mastersBoardIcon from "@/res/buttons/board_masters.svg";
import blitzBoardIcon from "@/res/buttons/board_blitz.svg";
import customBoardIcon from "@/res/buttons/board_custom.svg";
import { StaticImageData } from "next/image";


export function getGameTypeIcon(gameType: GameType | null): StaticImageData {
    if (gameType === null)
        return customBoardIcon;

    if (gameType === GameType.FINKEL)
        return finkelBoardIcon;
    if (gameType === GameType.MASTERS || gameType === GameType.OLD_MASTERS)
        return mastersBoardIcon;
    if (gameType === GameType.BLITZ)
        return blitzBoardIcon;

    return customBoardIcon;
}
