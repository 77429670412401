import styles from "./ByLine.module.scss";
import Link from "next/link";


interface ByLineProps {
    noLink?: boolean;
}


export function ByLine({ noLink }: ByLineProps) {
    return (
        <span className={styles.byline}>
            {"by "}

            {!noLink && (
                <Link
                    className={styles.name}
                    href="https://padraiglamont.com">

                    Padraig Lamont
                </Link>
            )}

            {noLink && (
                <div className={styles.name}>
                    Padraig Lamont
                </div>
            )}
        </span>
    );
}
