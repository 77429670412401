import { AuthRedirectTarget } from "@/ts/auth/check/AuthRedirectTarget";
import { AuthCheck } from "@/ts/auth/check/AuthCheck";
import { Path } from "@/ts/util/Path";


export function getAuthRedirectPath(
    target: AuthRedirectTarget | AuthCheck,
    redirectPathname?: string,
): Path {
    if (typeof target !== "string") {
        target = target.getRedirectTarget();
    }
    switch (target) {
        case AuthRedirectTarget.SIGN_IN:
            return new Path("/login")
                .withOptionalParam("redirect", redirectPathname);
        case AuthRedirectTarget.REGISTER:
            return new Path("/register")
                .withOptionalParam("redirect", redirectPathname);
        case AuthRedirectTarget.HOME:
            return new Path("/");
        case AuthRedirectTarget.ACCOUNT_SETUP:
            return new Path("/setup");
        case AuthRedirectTarget.DELETION_STATUS:
            return new Path("/account-deletion-status");
        default:
            throw new Error("Unknown redirect target: " + target);
    }
}
