import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { GameSettings } from "@/ts/royalur/model/GameSettings";


export class PacketOutFindGame extends PacketOut {
    public static readonly SETTINGS_KEY = "settings";

    private readonly settings: GameSettings;

    constructor(settings: GameSettings) {
        super(OutboundPacketType.FIND_GAME);
        this.settings = settings;
    }

    override writeContents(context: MessageIOContext): Record<string, any> {
        const settingsJson = context.getGameNotation().writeGameSettings(this.settings);
        return {
            [PacketOutFindGame.SETTINGS_KEY]: settingsJson,
        };
    }
}
