import { useQuery } from "@tanstack/react-query";
import { getLeaderboard, LeaderboardEntry } from "@/app/(api)/api/getLeaderboard/getLeaderboard";
import { LeaderboardType } from "@/ts/business/api/leaderboard/LeaderboardType";


export interface UseLeaderboardResult {
    leaderboard: LeaderboardEntry[] | undefined;
    leaderboardMetadata: Record<string, any> | undefined;
    leaderboardYourEntry: LeaderboardEntry | null | undefined;
    leaderboardLoading: boolean;
    leaderboardError: Error | null;
}


export function useLeaderboard(
    leaderboardType: LeaderboardType | null,
    from?: number,
    limit?: number,
): UseLeaderboardResult {
    from ??= 0;
    limit ??= 10;

    const { data, isLoading, error } = useQuery({
        queryKey: [
            "leaderboard",
            leaderboardType?.period.getID(),
            leaderboardType?.yearMonth,
            leaderboardType?.grouping.getID(),
            leaderboardType?.metric.getID(),
            from,
            limit,
        ],
        queryFn: () => getLeaderboard(leaderboardType, from, limit),
        staleTime: 60 * 1000,
        refetchInterval: 60 * 1000,
    });

    return {
        leaderboard: data?.entries,
        leaderboardMetadata: data?.metadata,
        leaderboardYourEntry: data?.yourEntry,
        leaderboardLoading: isLoading,
        leaderboardError: error,
    };
}
