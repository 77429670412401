import styles from "./LeaderboardWidget.module.scss";
import { Leaderboard } from "@/app_components/leaderboard/Leaderboard";
import { LeaderboardMetric } from "@/ts/business/api/leaderboard/LeaderboardMetric";
import React, { useEffect, useMemo, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { fadeInOut } from "@/app_util/fadeInOut";
import { useLeaderboard } from "@/app/(api)/api/getLeaderboard/useLeaderboard";
import { LeaderboardType } from "@/ts/business/api/leaderboard/LeaderboardType";
import { LeaderboardPeriod } from "@/ts/business/api/leaderboard/LeaderboardPeriod";
import { LeaderboardGrouping } from "@/ts/business/api/leaderboard/LeaderboardGrouping";
import { cn } from "@/ts/util/cn";
import { randElement } from "@/ts/util/random";


interface LeaderboardWidgetProps {
    generateDisplayLeaderboards: (yearMonth: number) => LeaderboardType[];
    from?: number;
    limit?: number;
    displayDescAsTitle?: boolean;
}


export function LeaderboardWidget({
    generateDisplayLeaderboards, from, limit, displayDescAsTitle,
}: LeaderboardWidgetProps) {

    const [leaderboardType, setLeaderboardType] = useState<LeaderboardType | null>(null);
    const {
        leaderboard,
        leaderboardYourEntry,
        leaderboardLoading,
        leaderboardError,
    } = useLeaderboard(leaderboardType, from, limit);

    const displayLeaderboards: LeaderboardType[] = useMemo(
        () => {
            const types = generateDisplayLeaderboards(
                LeaderboardType.calculateYearMonth(new Date())
            );
            if (types.length === 0)
                throw new Error("generateDisplayLeaderboards must return at least 1 leaderboard type");

            return types;
        },

        // We purposefully ignore generateDisplayLeaderboards changing.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        if (leaderboardType === null) {
            setLeaderboardType(randElement(displayLeaderboards));
        }
    }, [displayLeaderboards, leaderboardType, setLeaderboardType]);

    return (
        <div className={styles.container}>
            {leaderboardType && (
                <>
                    <div className={styles.subtitle_line}>
                        <AnimatePresence mode="wait">
                            <motion.div
                                className={styles.subtitle}
                                key={leaderboardType.createID()}
                                {...fadeInOut({ duration: 0.2 })}>

                                <p className={cn(
                                    displayDescAsTitle && styles.title_like_desc,
                                )}>
                                    {leaderboardType.createDesc()}
                                </p>
                            </motion.div>
                        </AnimatePresence>

                        {displayLeaderboards.length > 1 && (
                            <button
                                type="button"
                                onClick={() => {
                                    let next = displayLeaderboards[0];
                                    for (let index = 0; index < displayLeaderboards.length - 1; ++index) {
                                        if (displayLeaderboards[index] === leaderboardType) {
                                            next = displayLeaderboards[index + 1];
                                        }
                                    }
                                    setLeaderboardType(next);
                                }}>
                                Next
                            </button>
                        )}
                    </div>
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={leaderboardType.createID()}
                            {...fadeInOut({ duration: 0.2 })}>

                            <Leaderboard
                                leaderboard={leaderboard}
                                leaderboardYourEntry={leaderboardYourEntry}
                                leaderboardLoading={leaderboardLoading}
                                leaderboardError={leaderboardError}
                                formatValue={(_pos, _user, _prefs, stats) => {
                                    const grouping = leaderboardType.grouping;
                                    const metric = leaderboardType.metric;

                                    if (grouping === LeaderboardGrouping.OVERALL) {
                                        if (metric === LeaderboardMetric.ONLINE_WINS) {
                                            return `${stats.online_wins} Wins`;
                                        } else if (metric === LeaderboardMetric.PANDA_WINS) {
                                            return `${stats.panda_wins} Wins`;
                                        } else {
                                            throw new Error(`Unknown metric: ${metric.getID()}`);
                                        }
                                    } else if (grouping === LeaderboardGrouping.FINKEL) {
                                        if (metric === LeaderboardMetric.ONLINE_WINS) {
                                            return `${stats.online_finkel_wins} Wins`;
                                        } else if (metric === LeaderboardMetric.PANDA_WINS) {
                                            return `${stats.panda_finkel_wins} Wins`;
                                        } else {
                                            throw new Error(`Unknown metric: ${metric.getID()}`);
                                        }
                                    } else if (grouping === LeaderboardGrouping.BLITZ) {
                                        if (metric === LeaderboardMetric.ONLINE_WINS) {
                                            return `${stats.online_blitz_wins} Wins`;
                                        } else if (metric === LeaderboardMetric.PANDA_WINS) {
                                            return `${stats.panda_blitz_wins} Wins`;
                                        } else {
                                            throw new Error(`Unknown metric: ${metric.getID()}`);
                                        }
                                    } else if (grouping === LeaderboardGrouping.MASTERS) {
                                        if (metric === LeaderboardMetric.ONLINE_WINS) {
                                            return `${stats.online_masters_wins} Wins`;
                                        } else if (metric === LeaderboardMetric.PANDA_WINS) {
                                            return `${stats.panda_masters_wins} Wins`;
                                        } else {
                                            throw new Error(`Unknown metric: ${metric.getID()}`);
                                        }
                                    } else {
                                        throw new Error(`Unknown grouping: ${grouping.getID()}`);
                                    }
                                }} />
                        </motion.div>
                    </AnimatePresence>
                </>
            )}
        </div>
    );
}


interface SimpleLeaderboardWidgetProps {
    className?: string;
    period: LeaderboardPeriod;
    grouping: LeaderboardGrouping;
    metric: LeaderboardMetric;
    from?: number;
    limit?: number;
}


export function SimpleLeaderboardWidget({
    className, period, grouping, metric, from, limit,
}: SimpleLeaderboardWidgetProps) {
    return (
        <AnimatePresence mode="wait">
            <motion.div
                key={`${period.getID()}__${grouping.getID()}__${metric.getID()}`}
                className={className}
                {...fadeInOut()}>

                <LeaderboardWidget
                    generateDisplayLeaderboards={yearMonth => [
                        new LeaderboardType(period, yearMonth, grouping, metric),
                    ]}
                    from={from}
                    limit={limit}
                    displayDescAsTitle={true} />
            </motion.div>
        </AnimatePresence>
    );
}

