import { GameMode } from "@/ts/business/game/GameMode";
import { BotType } from "@/ts/business/game/BotType";
import { APIUser } from "@/ts/business/api/api_schema";
import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";
import { readStorage, writeStorage } from "@/ts/util/storage";


const PREFERRED_SETTINGS_KEY = "preferredGameSetupSettings";


export function readPreferredSettings(user: APIUser | null): KnownLobbySettings {
    const defaultGameSettings = KnownLobbySettings.DEFAULT;
    const jsonText: string | null = readStorage(PREFERRED_SETTINGS_KEY);
    if (jsonText === null)
        return defaultGameSettings;

    let settings = KnownLobbySettings.fromJSON(jsonText, defaultGameSettings);
    if (user)
        return settings;

    const botType = settings.getBotType();
    if (settings.getMode() === GameMode.COMPUTER
        && (botType === BotType.HARD || botType === BotType.PANDA)) {

        settings = settings.withBotType(BotType.MEDIUM);
    }
    return settings;
}


export function writePreferredSettings(settings: KnownLobbySettings) {
    writeStorage(PREFERRED_SETTINGS_KEY, settings.toJSON());
}
