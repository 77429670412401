
export function areNotificationsSupported(): boolean {
    return "Notification" in window;
}


export function hasNotificationDecision(): boolean {
    return areNotificationsSupported() && Notification.permission !== "default";
}


export function areNotificationsEnabled(): boolean {
    return areNotificationsSupported() && Notification.permission === "granted";
}


export async function requestNotificationPermission(): Promise<boolean> {
    if (!areNotificationsSupported())
        return false;
    if (hasNotificationDecision())
        return areNotificationsEnabled();

    const permission = await Notification.requestPermission();
    return permission === "granted";
}


export function showNotification(title: string, body: string): void {
    if (areNotificationsSupported() && areNotificationsEnabled()) {
        new Notification(title, {
            body,
        });
    }
}
