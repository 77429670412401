

export async function throwOnBadResponse(response: Response) {
    if (response.status === 200)
        return;

    let message: string | null = null;
    if (response.status === 400) {
        message = await response.text();
    }
    throw new Error(message ?? response.statusText);
}


export async function fetchWithThrow(
    input: string | URL | globalThis.Request,
    init?: RequestInit,
): Promise<Response> {
    const response = await fetch(input, init);
    await throwOnBadResponse(response);
    return response;
}
