import { Seconds } from "@/ts/util/units";
import { MotionProps } from "framer-motion";


export interface FadeInOutOptions {
    duration?: Seconds;
    delay?: Seconds;
    animateHeight?: boolean;
    animateHeightInclExit?: boolean;
}


export function fadeInOut(options?: FadeInOutOptions | Seconds): MotionProps {
    if (typeof options === "number") {
        options = { duration: options };
    }

    const animateHeight = options?.animateHeight || options?.animateHeightInclExit;

    const transition = {
        ease: "easeInOut",
        duration: options?.duration ?? 0.25,
    };
    return {
        initial: {
            opacity: 0,
            ...(animateHeight ? { height: 0 } : {}),
        },
        animate: {
            opacity: 1,
            ...(animateHeight ? { height: "auto" } : {}),
            transition: {
                delay: options?.delay,
                ...transition,
            },
        },
        exit: {
            opacity: 0,
            ...(animateHeight ? { height: (options?.animateHeightInclExit ? 0 : "auto") } : {}),
        },
        transition: transition,
    };
}


export function controlledFadeInOut(
    fadedIn: boolean, options?: FadeInOutOptions | Seconds,
): MotionProps {
    if (typeof options === "number") {
        options = { duration: options };
    }
    const transition = {
        ease: "easeInOut",
        duration: options?.duration ?? 0.25,
    };
    return {
        initial: {
            opacity: 0,
            ...(options?.animateHeight ? { height: 0 } : {}),
        },
        animate: (
            fadedIn ? ({
                opacity: 1,
                ...(options?.animateHeight ? { height: "auto" } : {}),
                transition: {
                    delay: options?.delay,
                    ...transition,
                },
            }) : ({
                opacity: 0,
                ...(options?.animateHeight ? { height: "auto" } : {}),
            })
        ),
        transition: transition,
    };
}
